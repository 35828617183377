import { Entity, parseEntityRef } from '@backstage/catalog-model';
import {
  EmptyState,
  InfoCardVariants,
  TableColumn,
  TableOptions,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import { isArray } from 'lodash';
import GroupIcon from '@material-ui/icons/Group';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getSystemByByName } from '../CatalogTable/CatalogTable';

/** @public */
export type RelatedEntitiesCardProps<T extends Entity> = {
  variant?: InfoCardVariants;
  title: string;
  columns: TableColumn<T>[];
  entityKind?: string;
  relationType: string;
  emptyMessage: string;
  emptyHelpLink: string;
  asRenderableEntities: (entities: Entity[]) => T[];
  tableOptions?: TableOptions;
};

/**
 * Further optimized Related Entities Card for better performance.
 */
export function RelatedEntitiesCard<T extends Entity>(
  props: RelatedEntitiesCardProps<T>,
) {
  const { title } = props;
  const [entities, setEntities] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const catalogApi = useApi(catalogApiRef);

  // Memoized fetch for group names, using parallel requests with Promise.all
  const fetchNamesByGroupId = useCallback(
    async (ids: string) => {
      if (!ids) return '';

      const split = ids.split(' ');
      if (split.length === 0) return '';

      try {
        const names = await Promise.all(
          split.map(async item => {
            const groupItem = { kind: 'group', name: item };
            const entityRef = await parseEntityRef(groupItem);
            const value = (await catalogApi.getEntityByRef(entityRef)) as any;
            return value?.spec?.profile?.displayName || 'Unknown';
          }),
        );
        return names.join(', ');
      } catch (error) {
        console.error('Error fetching group names:', error);
        return 'Error';
      }
    },
    [catalogApi],
  );

  // Memoized fetch for entity data, fetching in parallel
  const fetchEntities = useCallback(async () => {
    try {
      setLoading(true);
      const groupItem = window?.location.pathname.split('/')[4];
      const entityData = await getSystemByByName(groupItem);

      // Fetch owner names in parallel
      const processedEntities = await Promise.all(
        entityData.map(async (entity: any) => {
          const ownerNames = entity.spec?.owner.includes(' ')
            ? await fetchNamesByGroupId(entity.spec?.owner)
            : await fetchNamesByGroupId(entity.spec?.owner);
          return {
            ...entity,
            spec: {
              ...entity.spec,
              link: ownerNames,
            },
          };
        }),
      );

      setEntities(processedEntities);
    } catch (err) {
      setError('Failed to load entities');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [fetchNamesByGroupId]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  // Memoized owner name lookup to avoid redundant re-renders
  const GetNameById = useMemo(
    () =>
      ({ id }: { id: any }): JSX.Element => {
        const [loading, setLoading] = useState(true);

        useEffect(() => {
          const fetchData = async () => {
            if (isArray(id?.link)) {
            await Promise.all(
                id?.owner.split(',').map(async (owner: string) => {
                  const groupItem = {
                    kind: 'group',
                    name: owner,
                    namespace: 'default',
                  };
                  const entity = (await catalogApi.getEntityByRef(
                    groupItem,
                  )) as any;
                  return entity?.spec.profile?.displayName || 'Unknown';
                }),
              );
            }
            setLoading(false);
          };

          fetchData();
        }, [id]);

        if (loading) {
          return <div>Loading...</div>;
        }

        function GetGroupIcon() {
          return <GroupIcon style={{ fontSize: 10, color: 'grey' }} />;
        }

        return (
          <>
            {id?.link.includes(',') ? (
              id?.link.split(',').map((item: string) => {
                const modifiedItem = item.trimStart().trimEnd();
                const owner = modifiedItem.split(' ')[0];
                let modifiedOwner = '';
                if (owner.length === 1) {
                  modifiedOwner = owner.concat(item.split(' ')[1]);
                } else modifiedOwner = owner + '_' + modifiedItem.split(' ')[1];
                return (
                  <>
                    <GetGroupIcon />
                    <span>
                      <a
                        style={{ color: '#05164d' }}
                        href={`/catalog/default/group/dh_team_${modifiedOwner}`}
                      >
                        {item}
                      </a>
                    </span>
                    <br />
                  </>
                );
              })
            ) : (
              <span>
                {' '}
                <GetGroupIcon />
                <a
                  style={{ color: '#05164d' }}
                  href={`/catalog/default/group/${id?.owner}`}
                >
                  {id?.link}
                </a>
              </span>
            )}
          </>
        );
      },
    [catalogApi],
  );

  // Render component
  return (
    <Card data-testid="entity-has-systems-card">
      <CardHeader title={title} />
      <CardContent>
        {loading ? (
          <LinearProgress />
        ) : error ? (
          <div>{error}</div>
        ) : entities.length ? (
          <Table aria-label="group list" size="small">
            <TableHead>
              <TableRow>
                <TableCell>NAME</TableCell>
                <TableCell>OWNER</TableCell>
                <TableCell>DESCRIPTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entities
                .sort((a, b) => a.metadata.name.localeCompare(b.metadata.name))
                .map((row: any, i: number) => (
                  <TableRow key={row?.metadata?.name + i}>
                    <TableCell style={{ width: '10em' }}>
                      <EntityRefLink entityRef={row}>
                        <b>{row?.metadata?.title ?? row?.metadata?.name}</b>
                      </EntityRefLink>
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: 'break-word', width: '15em' }}
                    >
                      <GetNameById id={row?.spec} />
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: 'break-word', width: '30em' }}
                    >
                      {row?.metadata?.description
                        ?.substring(0, 200)
                        .concat('...')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyState missing="data" title="No Products" />
        )}
      </CardContent>
    </Card>
  );
}
