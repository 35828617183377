import React from 'react';
import { FC } from 'react';

type riskLevel = 'CRITICAL' | 'LOW' | 'HIGH' | 'MEDIUM';

const RiskLevel: FC<{ risk: riskLevel }> = ({ risk }) => {
  return (
    <div
      style={{
        backgroundColor:
          risk === 'CRITICAL'
            ? '#D32F2F'
            : risk === 'HIGH'
              ? '#FFA000'
              : risk === 'LOW'
                ? '#388E3C'
                : risk === 'MEDIUM'
                  ? '#FBC02D'
                  : '',
          textAlign:'center',
          color:'#fff',
          borderRadius:'5%'
      }}
    >
      <b>{risk}</b>
    </div>
  );
};

export default RiskLevel;
