
type requestType = {
  methodType: 'GET' | 'POST' | 'PATCH' | 'DELETE';
};
type Payload = {
  [key: string]: string | number | boolean | object | null;
};
export const isLocalHost = process.env.NODE_ENV==='production'?false:true
interface Config {
  getNumber(name: string): number;
  getString(name: string): string;
}
let user_Token = '';

function getHostUrl() {
  let _window = null;
  if (typeof window !== 'undefined') {
    _window = window.location;
  }

  return _window?.hostname === 'localhost'
    ? 'http://localhost:7007'
    : `https://${_window?.hostname}`;
}
export const hostUrl = getHostUrl();

export function removeSpecialCharacters(data: string) {
  const harmfulCharacters = /'|"|;|\\/g; // No @ts-expect-error needed here
  const sanitizedName = data.replace(harmfulCharacters, '');
  let escapedName = sanitizedName.replace(/&/g, '&amp;');
  escapedName = escapedName.replace(/</g, '&lt;');
  escapedName = escapedName.replace(/>/g, '&gt;');
  let doubleEscapedName = escapedName.replace(/&amp;/g, '&amp;amp;');
  doubleEscapedName = doubleEscapedName.replace(/&lt;/g, '&amp;lt;');
  doubleEscapedName = doubleEscapedName.replace(/&gt;/g, '&amp;gt;');
  doubleEscapedName = doubleEscapedName.replace(/>/g, '&gt;');
  doubleEscapedName = doubleEscapedName.replace(/"/g, '&quot;');
  doubleEscapedName = doubleEscapedName.replace(/'/g, '&apos;');
  doubleEscapedName = doubleEscapedName.replace(/&lt;/g, '<');
  doubleEscapedName = doubleEscapedName.replace(/&gt;/g, '>');
  doubleEscapedName = doubleEscapedName.replace(/</g, '&lt;');
  doubleEscapedName = doubleEscapedName.replace(/>/g, '&gt;');
  doubleEscapedName = doubleEscapedName.replace(/"/g, '&quot;');
  doubleEscapedName = doubleEscapedName.replace(/'/g, '&apos;');
  doubleEscapedName = doubleEscapedName.replace(/&amp;/g, '&');
  return doubleEscapedName;
}

export const urls = {
  yamlValidator: `api/catalogCustom/validator?url=`,
  systemsByGroup: `api/catalogCustom/systemsByGroup?name=`,
  getAllSystems: `api/catalogCustom/getAllSystems`,
  getComponentByServiceName: `api/catalogCustom/getComponentByServiceName?name=`,
  getSystemByServiceName: `api/catalogCustom/getSystemByServiceName?name=`,
  getAdrList: `api/adr/list?url=`,
  getAdrContentByName: `api/adr/file?url=`,
  getBase64Image: `api/catalogCustom/getBase64Image?url=`,
  isAzureProjectExists: `api/catalogCustom/checkIfProjectExists?name=`,
  getPipelineDataByTemplateName: `api/catalogCustom/getPipelineParamsByName?name=`,
  prepareGardRailsData: `api/techdocs/sync/default/domain/cop_dso`,
  getGuardRailsData: `api/techdocs/static/docs/default/domain/cop_dso/index.html`,
  getAllAdrs: `api/catalogCustom/getAllAdrs`,
  getEpicAvatarArr: `api/catalogCustom/getAvatarByName?name=`,
  getDevSecOpsEpics: `api/catalogCustom/getEpics`,
  getOwnershipCount: `api/catalogCustom/getOwnershipCount?name=`,
  getSystemsCountByTeam: `api/catalogCustom/getSystemsCountByTeam`,
  getContributorsByTeam: `api/catalog/entities/by-name/group/default/`,
  getTeamsDataById: `api/catalog/entities/by-name/domain/default/`,
  getConfigByName: `api/catalogCustom/getConfigByName?name=`,
  getMultiAdrList: `api/catalogCustom/listMultipleAdr?url=`,
  getPdfFile: `api/catalogCustom/getPdfFile?url=`,
  getAllProcessedOwners:`api/catalogCustom/getAllProcessedOwners`,
  getTrackSpaceUser:`api/catalogCustom/getTrackSpaceUser?user=`,
  getCreatedTrackSpaceId:`api/catalogCustom/getCreatedTrackSpaceId?id=`,
  getPiplineStatusByTasKId:`api/catalogCustom/getPipelineStatus?id=`,
  getAllUsers:`api/catalogCustom/getAllUsers`,
  getAzureLogsById:`api/catalogCustom/getAzureLogsById?logId=`,
  runComponentAzurePipelineStatus:`api/catalogCustom/runComponentAzureDevOps?token=`,
  getFolderPath:`api/catalogCustom/getFolderPathByName`,
  getLanguageById:`api/catalogCustom/getLinguistByURl?id=`,
  getPowerBiReport:`api/catalogCustom/getPowerBiReport?url=`,
  checkIfGroupExists:`api/catalogCustom/checkIfGroupExists?id=`,
  oIPJsonValidateEndpoint:`https://openapi-validator.api-dev.lhgroup.de/api/v1/validate`,
  // DAP Feature Flags
  getTeams:`api/dap/teams`,
  createTeams:`api/dap/teams`,
  updateTeam:`api/dap/teams`,
  deleteTeam:`api/dap/teams/`,
  getTeamConfig:`api/dap/config/`,
  setConfig:`api/dap/config/`,
  checkmarxScanResultByProject:`api/catalogCustom/SastReportByProjectName?projectName=`
};

export const setUserToken = (token: string) => {
  user_Token = token || '';
  return user_Token;
};

export const token = () => user_Token;

export { dbNames } from './utils';

export const requestHeader = (type: requestType, payload?: Payload) => {
  let requestConfig: RequestInit = {
    method: type.methodType,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token()}`,
    },
  };

  // Add body for POST requests
  if ((type.methodType === 'POST' || type.methodType==='PATCH') && payload) {
    requestConfig = {
      ...requestConfig,
      body: JSON.stringify(payload),
    };
  }
  return requestConfig;
};

export const getEnv = (config: Config, name: string) => {
  const envtoken = config.getString(name);
  return envtoken;
};

export const getEnvNumber = (config: Config, name: string) => {
  const env_token = config.getNumber(name);
  return env_token;
};

export async function fetchData(url: string, payload?: Payload, isPatch?:boolean, isDelete?:boolean): Promise<any> {
  try {
    const methodType = payload ? (isPatch ? 'PATCH' : isDelete? 'DELETE':'POST') : 'GET';

    // Fetch the response using the requestHeader
    const responseContent = await fetch(
      `${hostUrl}/${url}`,
      requestHeader({ methodType }, payload),
    );

    const contentType = responseContent.headers.get('Content-Type');

    // Declare a variable for resolved content
    let resolvedContent;

    if (contentType) {
      // Check for JSON content type
      if (contentType.includes('application/json')) {
        resolvedContent = await responseContent.json();
      }
      // Check for PDF content type (return as Blob)
      else if (contentType.includes('application/pdf')) {
        resolvedContent = await responseContent.blob();
      }
      // Handle plain text or other content types
      else {
        resolvedContent = await responseContent.text();
      }
    }

    // Check if the response is successful
    if (responseContent.ok) {
      // Handle PDF separately by creating a Blob URL
      if (contentType && contentType.includes('application/pdf')) {
        return URL.createObjectURL(resolvedContent); // Return Blob URL for PDF display
      }
      return resolvedContent; // Return resolved content (JSON or text)
    }

    // Reject if the response was not successful
    return Promise.reject(resolvedContent);
  } catch (error) {
    // Handle any errors
    if (error instanceof Error) {
      return Promise.reject(error.message);
    }
  }

  return null;
}
