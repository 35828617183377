import { useEntity } from '@backstage/plugin-catalog-react';
import { fetchData, urls } from '@internal/backstage-plugin-dh-dock-shared-common';
import { Paper, TableContainer } from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import { isArray } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

import AdrList from '../../components/Adr';
import { ADR } from '../../components/Adr/Adr';
import AdrContent from '../../components/Adr/content';
import { ErrorPage } from '../../components/ui/Error';
import { getAdrContentBytitle, getAdrListByUrl } from '../../services/adr.service';

import './Adr.css';

let baseUrl = '';
const ADRList: React.FC = () => {
  const { entity } = useEntity();
  const url = window.location.pathname;
  const splitUrl = url.split('/')[4];
  const [state, setState] = useState({
    retrievedContent: '',
    retrievedPathUrl: '',
    baseUrl: '',
    subUrl: '',
    selected: '',
    error: '',
    adrListArr: [] as ADR[],
    isLoading: true,
    treePath: '',
    shouldFetch: false,
  });
  const location = useLocation();
  const navigate = useNavigate();

  const getContentByTitle = useCallback(
    async (id: string) => {

      if (id.toLowerCase().endsWith('MD'.toLowerCase())) {
        id = id.replace('//', '/');
        const path = await getPath();
        const getContent = await getAdrContentBytitle(
          path.concat('/').concat(id),
        );

        const splitId = id?.split('/')[0];
        setState(prev => ({
          ...prev,
          subUrl: splitId || id.substring(1)?.split('/')[0],
          retrievedContent: getContent.data,
        }));
        return getContent;
      }

      return '';
    },
    [splitUrl, state.retrievedPathUrl, url],
  );

  const handleOnClick = useCallback(
    async (id: string, url?: string) => {
      setState(prev => ({
        ...prev,
        selected: id,
        subUrl: id.split('/')[0],
      }));

      if (url && id.split('/')[0] !== url) {
        setState(prev => ({
          ...prev,
          subUrl: url,
        }));
        getContentByTitle(url.concat('/').concat(id));
      } else {
        getContentByTitle(id);
      }

      navigate(id);
    },
    [navigate, getContentByTitle, state.retrievedPathUrl],
  );

  const getAdrDocumentList = useMemo(() => {
    
    return async (url: string) => {
      try {
        const List = await getAdrListByUrl(url);

        if (isArray(List.data)) {
          setState(prevState => ({
            ...prevState,
            baseUrl: url,
            isLoading: false,
            adrListArr: List.data.filter(
              (x: ADR) => !x?.path!!.toLowerCase().endsWith('.png'),
            ),
          }));
        } else {
          setState(prev => ({
            ...prev,
            error: List?.message,
          }));
        }
      } catch (ex) {
        setState(prev => ({
          ...prev,
          isLoading: false,
          error: 'while trying to fetch the desired adr due to timeout.',
        }));
      }
    };
  }, []);

  async function getPath() {
    let url_ = entity?.metadata?.annotations!![
      'backstage.io/managed-by-location'
    ] as string;
    let adrLocation = entity?.metadata?.annotations!![
      'backstage.io/adr-location'
    ] as string;
    adrLocation = adrLocation.includes('/')
      ? adrLocation.split('/').pop() as string
      : adrLocation;
    const path = await fetchData(
      `${urls.getFolderPath}?url=${url_.replace('url:', '').split('?path=')[0]}&folder=${adrLocation}`,
    );
    baseUrl = path;
    return path;
  }

  useEffect(
    () => {
      const getData = async () => {
        //check if path is okay

        const path = await getPath();
        await getAdrDocumentList(path as string);
        const _selected = state.adrListArr.find((x: ADR) =>
          x.name.toLowerCase().endsWith('.md'),
        );

        if (_selected)
          handleOnClick(_selected?.path!!).catch((ex: Error) => {
            setState(prev => ({
              ...prev,
              isLoading: false,
              error: ex.message,
            }));
          });
      };
      getData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity.kind, getAdrDocumentList],
  );

  useEffect(() => {
    
    const path = location.pathname?.split('/adr')[1] as string;
    const newPath=path.substring(1);
    if (state.adrListArr?.length > 0) {
      
      let newUrl:ADR={
        name: '',
        status: '',
        selected: ''
      }
      if (path) {
       newUrl=state.adrListArr.find(x=>x.path?.toLowerCase().includes(newPath.toLowerCase()))!!
        if(newUrl.initialTreeRelativePath && newUrl.initialTreeRelativePath!==path.substring(1).split('/')[0])
        getContentByTitle(newUrl?.initialTreeRelativePath?.concat(path) as string);
        else
        getContentByTitle(path.substring(1) as string);
        return;
      }
      const _selected = state.adrListArr.filter((x: ADR) =>
        x.path?.toLowerCase().endsWith('.md'),
      );
      if (_selected && _selected.length > 0) {
        setState(prev => ({
          ...prev,
          selected: _selected[0].path!!,
        }));
        
        if(newUrl?.initialTreeRelativePath)
        getContentByTitle(newUrl?.initialTreeRelativePath?.concat('/').concat(_selected[0].path!!) as string);
        else
        getContentByTitle(_selected[0].path!! as string);
      }
    }
  }, [
    getContentByTitle,
    state.adrListArr,
    location.pathname,
  ]);

  return (
    <>
      <h1>Architecture Decision Records</h1>
      {state.isLoading && !state.error && <LinearProgress />}
      {!state.isLoading && state.error && (
        <ErrorPage message={state.error} type="error" />
      )}
      {!state.isLoading && !state.error && state.adrListArr?.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
          <TableContainer
            component={Paper}
            style={{ width: '35rem', padding: '2rem' }}
          >
            <div>
              {state.adrListArr?.length > 0 &&
                state.adrListArr.map(item => {
                  if (item.path?.endsWith('.md'))
                    return (
                      <AdrList
                        key={item.path}
                        status={item.status}
                        name={item.name}
                        title={item.title}
                        date={item.date}
                        selected={state.selected}
                        path={item?.path}
                        initialTreeRelativePath={item.initialTreeRelativePath}
                        onClick={handleOnClick}
                      />
                    );
                  return null;
                })}
            </div>
          </TableContainer>
          <AdrContent
            baseUrl={
              state.subUrl ? baseUrl.concat('/').concat(state.subUrl) : baseUrl
            }
            retrievedContent={state.retrievedContent}
          />
        </div>
      )}
    </>
  );
};

export default ADRList;
