export const groupsDocumentation =
  'https://dock.dh.lufthansa-group.com/docs/default/System/DH-Dock/tutorials/onboarding/#step-6-setup-group-information';


const validNameRegex = new RegExp('^[a-zA-Z]+$');
export const isValidName = (
  input: string,
  unavailableNames: string[],
): boolean => {
  const result = validNameRegex.exec(input);
  return (
    (result && result.length === 1 && !unavailableNames.includes(input)) ??
    false
  );
};