import { isAdrAvailable } from '@backstage/plugin-adr';
import { EntityConsumedApisCard, EntityProvidedApisCard } from '@backstage/plugin-api-docs';
import { EntityAzureGitTagsContent, EntityAzurePullRequestsContent, isAzureDevOpsAvailable } from '@backstage/plugin-azure-devops';
import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout, EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import { Grid } from '@material-ui/core';
import React from 'react';

import ADRList from '../../../pages/Adr';
import { CustomDomainEntityLayout } from '../../../pages/EntityLayout/CustomDomainEntityLayout';
import { cicdContent } from '../content/cicdContent';
import { techdocsContent } from '../content/techdocsContent';
import { useEntity } from '@backstage/plugin-catalog-react';
import CheckMarx from '../../../pages/Checkmarx';

export const myCustomFilterFn = (path: string): boolean => {
  if (path === 'template.md' || path === 'index.md' || path === 'README.md') {
    return false;
  }
  // Match all files following the pattern title-with-dashes.md
  return /^.+\.md$/.test(path);
};


const isSCheckmarxAvailable=()=>{
  const {entity}=useEntity();
  const project=entity?.metadata?.annotations!!["checkmarx.com/project"]
  return project?true:false;
}

const serviceEntityPage = (overviewContent: JSX.Element) => {
  return (
    <>
      <CustomDomainEntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/ci-cd" title="CI/CD">
          {cicdContent(isAzureDevOpsAvailable)}
        </EntityLayout.Route>

        <EntityLayout.Route
          if={isSonarQubeAvailable}
          path="/sonarqube"
          title="SonarQube"
        >
          <EntitySonarQubeCard variant="gridItem" />
        </EntityLayout.Route>
        <EntityLayout.Route
          if={isSCheckmarxAvailable}
          path="/checkmarx"
          title="Checkmarx"
        >
         <CheckMarx/>
        </EntityLayout.Route>

        <EntityLayout.Route
          if={isAzureDevOpsAvailable}
          path="/pull-requests"
          title="Pull Requests"
        >
          <EntityAzurePullRequestsContent defaultLimit={25} />
        </EntityLayout.Route>

        <EntityLayout.Route
          if={isAzureDevOpsAvailable}
          path="/git-tags"
          title="Git Tags"
        >
          <EntityAzureGitTagsContent />
        </EntityLayout.Route>

        <EntityLayout.Route path="/api" title="API">
          <Grid>
            <Grid item md={12}>
              <EntityProvidedApisCard />
            </Grid>
            <br />
            <Grid item md={12}>
              <EntityConsumedApisCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
              <EntityDependsOnComponentsCard variant="gridItem" />
            </Grid>
            <Grid item md={6}>
              <EntityDependsOnResourcesCard variant="gridItem" />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
          {techdocsContent}
        </EntityLayout.Route>

        <EntityLayout.Route if={isAdrAvailable} path="/adr" title="ADR">
          <>
            <ADRList />
          </>
        </EntityLayout.Route>
      </CustomDomainEntityLayout>
    </>
  );
};

const websiteEntityPage = (overviewContent: JSX.Element) => (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent(isAzureDevOpsAvailable)}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isSonarQubeAvailable}
      path="/sonarqube"
      title="SonarQube"
    >
      <EntitySonarQubeCard variant="gridItem" />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export const componentPage = (overviewContent: JSX.Element) => (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage(overviewContent)}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage(overviewContent)}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{serviceEntityPage(overviewContent)}</EntitySwitch.Case>
  </EntitySwitch>
);
