import React, { useCallback, useState } from "react"
import { FieldExtensionComponentProps } from "@backstage/plugin-scaffolder-react"
import { urls } from "@internal/backstage-plugin-dh-dock-shared-common"

export const OIPFileUploadExtension = ({
  onChange,

}: FieldExtensionComponentProps<string>) => {
  const [error,setError]=useState<string|null>(null);
  const [isLoading,setIsLoading]=useState(false);
  const [fileId,setFileId]=useState<string|null>(null);

  const validateJsonFile=useCallback(async(file:any)=>{
    setError('')
    const formData=new FormData();
    formData.append('openapiFile',file)
    try {
      const validateFile=await fetch(urls.oIPJsonValidateEndpoint,{
        method:'POST',
        
        body:formData
      });
      if(validateFile.ok){
        const {id}=await validateFile.json()
        setFileId(id)
        onChange(id); 
        setIsLoading(false)
      }
      else{
        setError(await validateFile.text())
      }
    } catch (error) {
      if(error instanceof Error){
        console.error("An error occured",error.message)
        setError(error.message)
      }
      setIsLoading(false)
    }
    
  },[])


  const handleFileOnChange=(file:React.ChangeEvent<HTMLInputElement>)=>{
    // validate selected file that it is a Json file extention
    const {files}=file.target;
    if(files!!?.length>0){
      const {type}=files!![0];
      if(type!=='application/json'){
        setError('Only Json Files Allowed');
        return;
      }
      setIsLoading(true)
      // call api to validate the file and return unique id
      validateJsonFile(files!![0])
    }

  }

  return (
    <div>
    Validate Json File.
    <br/>
    <br/>
    <input type="file" accept=".json" onChange={handleFileOnChange}/>
    <br/>
    <div>{isLoading && <label>Validating File......</label>}</div>
    <label style={{color:'red'}}>{error}</label>
    {fileId &&<div>Validated file Id: <label style={{color:'green'}}>{fileId}</label></div>}
    </div>
    
  )
}

