import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import RiskLevel from '../../components/ui/Risk';

type counters = {
  severity: string;
  counter: number;
};

const VulnerabilityChart = ({ data }: any) => {
  const chartRef = useRef<SVGSVGElement | null>(null);
  const critical:number = data?.report?.totalCounters?.severityCounters.find((x:counters)=>x.severity.toLowerCase()==='Critical'.toLowerCase())?.counter??0
  const high:number = data?.report?.totalCounters?.severityCounters.find((x:counters)=>x.severity.toLowerCase()==='High'.toLowerCase())?.counter??0
  const medium:number = data?.report?.totalCounters?.severityCounters.find((x:counters)=>x.severity.toLowerCase()==='Medium'.toLowerCase())?.counter??0
  const low :number = data?.report?.totalCounters?.severityCounters.find((x:counters)=>x.severity.toLowerCase()==='Low'.toLowerCase())?.counter??0
  const info:number = data?.report?.totalCounters?.severityCounters.find((x:counters)=>x.severity.toLowerCase()==='Info'.toLowerCase())?.counter??0

  useEffect(() => {
    if (!chartRef.current) return;

    const svg = d3.select(chartRef.current);
    svg.selectAll('*').remove(); // Clear previous render

    // Chart dimensions
    const width = 500,
      height = 400;
    const margin = { top: 20, right: 30, bottom: 50, left: 50 };

    // Prepare data
    const vulnerabilities = [
      { name: 'Critical', value: critical, color: '#D32F2F' }, // Red
      { name: 'High', value: high, color: '#FFA000' }, // Orange
      { name: 'Medium', value: medium, color: '#FBC02D' }, // Yellow
      { name: 'Low', value: low, color: '#388E3C' }, // Green
      { name: 'Info', value: info, color: '#B5B0AD' }, // Green
    ];

    // Scales
    const xScale = d3
      .scaleBand()
      .domain(vulnerabilities.map(d => d.name))
      .range([margin.left, width - margin.right])
      .padding(0.4);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(vulnerabilities, d => d.value) || 1])
      .nice()
      .range([height - margin.bottom, margin.top]);

    // Draw bars
    const bars = svg
      .append('g')
      .selectAll('rect')
      .data(vulnerabilities)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.name)!)
      .attr('y', d => yScale(d.value))
      .attr('height', d => height - margin.bottom - yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('fill', d => d.color)
      .attr('rx', 5);

    // Display value on hover
    const tooltip = svg
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('opacity', 0)
      .style('font-size', '14px')
      .style('fill', 'white');

    // Hover effect
    bars
      .on('mouseover', function (event, d) {
        // Get the x and y positions to display the tooltip above the bar
        const [x, y] = d3.pointer(event);

        // Display the value on top of the bar
        tooltip
          .transition()
          .duration(200)
          .attr('x', x)
          .attr('y', y - 10)
          .attr('opacity', 1)
          .text(d.value);
      })
      .on('mouseout', function () {
        // Hide the tooltip when mouse leaves the bar
        tooltip.transition().duration(200).attr('opacity', 0);
      });

    // X-Axis
    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));

    // Y-Axis
    svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));
  }, [data]);


  const total=info + low+ medium + high + critical;
  

  
  return (
    <>
      <h2>Checkmarx Vulnerability Overview</h2>
      <div style={{ textAlign: 'center' }}>
        Last Scan Id: <b>{data?.report?.enginesData[0]?.lastScanId} </b>
      </div>
      <div style={{ textAlign: 'center' }}>
        Last Scan Date: <b>{data?.report?.lastScanDate?.split('T')[0]}</b>
      </div>
      

      <section style={{ textAlign: 'center' }}>
        <a
          href={data?.moreDetailsUrl?.concat('/')}
          style={{ color: 'blue' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          More details
        </a>
      </section>

      <br />
      <div
        style={{
          display: 'flex',
          gap: 3,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg ref={chartRef} width={600} height={500}></svg>
        <section style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <b>Vulnerability score:</b>
          <b>Critical : {critical}</b>
          <b>High : {high}</b>
          <b>Medium : {medium}</b>
          <b>Low : {low}</b>
          <b>Info : {info}</b>
          <br />
          <b>Total : {total} </b>

          <br />

           <RiskLevel risk={data?.report?.riskLevel}  />

          <br />
        </section>
      </div>
    </>
  );
};

export default VulnerabilityChart;
