/* eslint-disable */

// ESLint will ignore this entire file
import React, { useState } from 'react';
import { TextEditComponent } from '../TextEditComponent';

import { Value } from '../TreeTypeComponent/TreeTypeComponent';
import { isValidName } from '../../../utils';

interface InlineListProps {
  elements: string[];
  onValuesUpdate: (newValues: string[]) => void;
}

const InlineListComponent = ({ elements, onValuesUpdate }: InlineListProps) => {
  const [inEditState, setEditState] = useState(false);
  const [newInputValue, setNewInputValue] = useState(elements.join(', '));

  const onInputChange = (value: string) => {
    setNewInputValue(value);
  };

  const validValuesRegex = new RegExp(
    '^(([A-Z][A-Z_]*)([,][\\s][A-Z][A-Z_]*)*)$',
  );
  const isValidValues = (input: string): boolean => {
    const result = validValuesRegex.exec(input);
    return (
      result != null &&
      result![0].split(',').length === new Set(result![0].split(',')).size // No repeating keys / uniqueness
    );
  };

  if (inEditState) {
    return (
      <>
        <input
          style={{
            width: 'auto',
            marginRight: '6px',
            backgroundColor: isValidValues(newInputValue)
              ? 'lightgreen'
              : 'pink',
          }}
          value={newInputValue}
          onChange={event => onInputChange(event.target.value)}
        ></input>
        {isValidValues(newInputValue) ? (
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onValuesUpdate(newInputValue.replace(/ /g, '').split(','));
              setEditState(!inEditState);
            }}
          >
            ✅ Accept
          </button>
        ) : (
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => setEditState(!inEditState)}
          >
            <span
              style={{
                textShadow:
                  'black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px',
                color: 'red',
              }}
            >
              ↩
            </span>{' '}
            Cancel
          </button>
        )}
      </>
    );
  } else {
    return elements.map((element, index) => {
      return (
        <span
          key={element}
          style={{
            paddingRight: index == elements.length - 1 ? '20px' : '0px',
          }}
          onClick={() => setEditState(!inEditState)}
        >
          {element}
          {index != elements.length - 1 ? ', ' : ''}
        </span>
      );
    });
  }
};

export function getOverrideName(override: Override): string {
  return getAllChildsName(override).join('_');
}
function getAllChildsName(override: Override): string[] {
  return override.child
    ? [override.name].concat(getAllChildsName(override.child))
    : [override.name];
}


export function generateCombinations(
  params: Param[],
  overrideOrder: Override,
): string[] {
  function traverse(
    params: Param[],
    override: Override,
    prefix?: string,
  ): string[] {
    const parameter = params.find(
      (param: Param) => param.name === override.name,
    );

    if (override.child) {
      let result: string[] = [];
      parameter!.values.forEach(item => {
        result = result.concat(
          traverse(
            params,
            override.child!,
            prefix ? prefix + '_' + item : item,
          ),
        );
      });
      return result;
    } else {
      return prefix
        ? parameter!.values.map(item => {
            return prefix + '_' + item;
          })
        : parameter!.values;
    }
  }
  return traverse(params, overrideOrder);
}

export const getOverride = (
  values: Value[],
  key: string,
  value: string,
): string | number | boolean | string[] | null => {
  let overrides = values.find(value => value.key === key)?.overrides;
  if (overrides) if (overrides[value] !== undefined) return overrides[value];

  return null;
};

function allElementsInSecondArray(
  firstArray: string[],
  secondArray: string[],
): boolean {
  return firstArray.every(element => secondArray.includes(element));
}

export const getOverrideColor = (
  allOverrides: Override[],
  params: Param[],
  currentOverride: Override,
  values: Value[],
  key: string,
  value: string,
): string => {
  let overrides = values.find(value => value.key === key)?.overrides;
  if (overrides)
    if (overrides[value] !== undefined) {
      let foundFirst = false;
      let afterCurrent = allOverrides.filter(override => {
        if (foundFirst) return true;
        else if (override == currentOverride) foundFirst = true;
        return override
      });

      let partialOverride = false;
      for (let override of afterCurrent) {
        if (
          allElementsInSecondArray(
            getAllChildsName(currentOverride),
            getAllChildsName(override),
          )
        ) {
          const levelKeys = generateCombinations(params, override).filter(
            element => {
              return allElementsInSecondArray(
                value.split('_'),
                element.split('_'),
              );
            },
          );
          let count = 0;
          for (let levelKey of levelKeys) {
            if (overrides[levelKey] !== undefined) {
              partialOverride = true;
              count++;
            }
          }
          if (count == levelKeys.length) return 'pink';
        }
      }

      if (!partialOverride) return 'palegreen';
      else return '#E5B25D';
    }

  return 'transparent';
};

export const isAnyOverrided = (value: Value, keys: string[]): boolean => {
  for (const key of Object.keys(value?.overrides ?? [])) {
    if (keys.includes(key)) return true;
  }
  return false;
};

export const generateFinalValues = (
  params: Param[],
  overrides: Override[],
  value: Value,
): [string, [string | number | boolean | string[], number]][] => {
  let keys: string[] = [];
  if (!value) return [];

  keys = generateKeys(getAllChildsName(overrides[overrides.length - 1]));

  let datas = keys.map(key => {
    let element: [string, [string | number | boolean | string[], number]] = [
      key,
      [value.defaultValue, 0],
    ];
    return element;
  });

  overrides.forEach((override, overrideIndex) => {
    let paramKeys = generateKeys(getAllChildsName(override));
    paramKeys.forEach(paramKey => {
      let paramKeyName = paramKey.split('-').join('_'); // act like renameAll() as it doesn't exist
      datas.forEach((data, index) => {
        if (
          paramKey.split('-').every(value => data[0].split('-').includes(value))
        )
          if (value.overrides && paramKeyName in value.overrides)
            datas[index] = [
              data[0],
              [value.overrides[paramKeyName], overrideIndex + 1],
            ];
      });
    });
  });

  return datas;

  function generateKeys(parameterNames: string[]): string[] {
    return generateCombinations(parameterNames);

    function generateCombinations(data: string[]): string[] {
      const results: string[] = [];

      function generateRecursive(index: number, current: string) {
        if (index === data.length) {
          results.push(current);
          return;
        }

        const paramName = data[index];
        const parameter = params.find(param => param.name === paramName);
        if (!parameter) return;

        parameter.values.forEach(value => {
          const separator = current ? '-' : '';
          generateRecursive(index + 1, current + separator + value);
        });
      }

      generateRecursive(0, '');

      return results;
    }
  }
};

export type Param = {
  name: string;
  values: string[];
};

export type Override = {
  name: string;
  child?: Override;
};

export type TreeParam = {
  parameters: Param[];
  overrideOrder: Override[];
};

interface TreeProps {
  treeParam: TreeParam;
  onParamAdd: (newParam: Param) => void;
  onParamUpdate: (oldParamName: string, newParam: Param) => void;
  onParamDelete: (oldParamName: string) => void;
  onOverrideUpdate: (overrides: Override[]) => void;
}

const DisplayTreeDataComponent = ({
  treeParam,
  onParamAdd,
  onParamUpdate,
  onParamDelete,
  onOverrideUpdate,
}: TreeProps) => {
  const [newParamName, setNewParamName] = useState('');
  const [overrideSelected, setOverrideSelected] = useState('');

  return (
    <>
      <h3>Parameters</h3>
      <table style={{ border: '1px solid black' }}>
        <tbody>
          <tr>
            {treeParam.parameters.map(item => {
              return (
                <td key={item.name} style={{ paddingRight: '20px' }}>
                  <TextEditComponent
                    currentName={item.name}
                    unavailableNames={treeParam.parameters.map(
                      param => param.name,
                    )}
                    onNameChange={newName => {
                      onParamUpdate(item.name, {
                        ...item,
                        name: newName,
                      });
                    }}
                    onDelete={() => onParamDelete(item.name)}
                  />
                </td>
              );
            })}
          </tr>
          <tr>
            {treeParam.parameters.map(item => {
              return (
                <td key={item.name}>
                  <InlineListComponent
                    elements={item.values}
                    onValuesUpdate={newValues => {
                      onParamUpdate(item.name, { ...item, values: newValues });
                    }}
                  />
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      <input
        placeholder="new parameter name"
        value={newParamName}
        onChange={event => setNewParamName(event.target.value)}
        style={{
          backgroundColor: isValidName(
            newParamName,
            treeParam.parameters.map(param => param.name),
          )
            ? 'lightgreen'
            : 'pink',
        }}
      ></input>
      <button
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (
            isValidName(
              newParamName,
              treeParam.parameters.map(param => param.name),
            )
          ) {
            onParamAdd({ name: newParamName, values: ['Default'] });
          }
        }}
      >
        Add Parameter
      </button>
      <hr />
      <h3>Override Order</h3>
      <table style={{ border: '1px solid black' }}>
        <tbody>
          <tr>
            <td>Order</td>
            <td>Parameters</td>
            <td>
              <select>
                <option disabled>-- select an option --</option>
                {treeParam.parameters.map((param, index) => {
                  return (
                    <option
                      key={'option' + param.name + index}
                      onClick={() => setOverrideSelected(param.name)}
                    >
                      {param.name}
                    </option>
                  );
                })}
              </select>
            </td>
          </tr>
          <tr>
            <td>0</td>
            <td>Default</td>
          </tr>
          {treeParam.overrideOrder.map((override, index) => {
            return (
              <tr key={override.name + index}>
                <td>{index + 1}</td>
                <td>
                  {getOverrideName(override)}
                  {override.child && (
                    <span
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        function removeLastChild(
                          override?: Override,
                        ): Override | undefined {
                          if (override && override.child) {
                            return {
                              ...override,
                              child: removeLastChild(override.child),
                            };
                          }
                          return;
                        }
                        onOverrideUpdate(
                          treeParam.overrideOrder.map((item, internalIndex) => {
                            return index == internalIndex
                              ? (removeLastChild(item) ?? item)
                              : item;
                          }),
                        );
                      }}
                    >
                      {' '}
                      ⨯
                    </span>
                  )}
                </td>
                <td>
                  <button
                    style={{
                      float: 'right',
                      marginLeft: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onOverrideUpdate(
                        treeParam.overrideOrder.filter(
                          (item, internalIndex) => {
                            if (index != internalIndex) return item;
                            return item;
                          },
                        ),
                      );
                    }}
                  >
                    Remove line
                  </button>
                  <button
                    disabled={
                      overrideSelected == '' ||
                      getAllChildsName(override).includes(overrideSelected)
                    }
                    style={{
                      float: 'right',
                      marginLeft: '4px',
                      cursor: getAllChildsName(override).includes(
                        overrideSelected,
                      )
                        ? 'default'
                        : 'pointer',
                    }}
                    onClick={() => {
                      function addLastChild(override?: Override): Override {
                        if (override && override.child) {
                          return {
                            ...override,
                            child: addLastChild(override.child),
                          };
                        }
                        return {
                          ...override!,
                          child: { name: overrideSelected },
                        };
                      }
                      onOverrideUpdate(
                        treeParam.overrideOrder.map((item, internalIndex) => {
                          return index == internalIndex
                            ? addLastChild(treeParam.overrideOrder[index])
                            : item;
                        }),
                      );
                    }}
                  >
                    Add
                  </button>
                  <button
                    disabled={
                      overrideSelected == '' ||
                      override.child !== undefined ||
                      override.name == overrideSelected
                    }
                    style={{
                      float: 'right',
                      marginLeft: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      onOverrideUpdate(
                        treeParam.overrideOrder.map((item, internalIndex) => {
                          return item.name != override.name ||
                            index != internalIndex
                            ? item
                            : {
                                ...item,
                                name: overrideSelected,
                              };
                        }),
                      );
                    }}
                  >
                    Replace First
                  </button>
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan={2}>
              <button
                onClick={() =>
                  onOverrideUpdate([
                    ...treeParam.overrideOrder,
                    { name: treeParam.parameters[0].name },
                  ])
                }
              >
                Add new override
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

interface TreeParamProps {
  treeParam: TreeParam;
  onParamAdd: (newParam: Param) => void;
  onParamUpdate: (oldParamName: string, newParam: Param) => void;
  onParamDelete: (oldParamName: string) => void;
  onOverrideUpdate: (overrides: Override[]) => void;
}

export const TreeParamComponent = ({
  treeParam,
  onParamAdd,
  onParamUpdate,
  onParamDelete,
  onOverrideUpdate,
}: TreeParamProps) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const isDebug = queryParameters.get('debug') != null;

  return (
    <div style={{ display: 'flex', paddingTop: '10px' }}>
      <div>
        {treeParam && (
          <DisplayTreeDataComponent
            treeParam={treeParam}
            onParamAdd={onParamAdd}
            onParamUpdate={onParamUpdate}
            onParamDelete={onParamDelete}
            onOverrideUpdate={onOverrideUpdate}
          />
        )}
      </div>
      {isDebug && (
        <div style={{ marginLeft: '10px', width: '300px' }}>
          <textarea
            style={{ height: '100%', width: '100%' }}
            readOnly={true}
            value={JSON.stringify(treeParam, null, 2)}
          ></textarea>
        </div>
      )}
    </div>
  );
};
