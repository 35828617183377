/* eslint-disable */

// ESLint will ignore this entire file
import { isValidName } from '../../../utils';
import React, { useState } from 'react';


interface TextEditProps {
  currentName: string;
  unavailableNames: string[];
  onNameChange: (name: string) => void;
  onDelete: () => void;
}

export const TextEditComponent = ({
  currentName,
  unavailableNames,
  onNameChange,
  onDelete,
}: TextEditProps) => {
  const [inEditState, setEditState] = useState(false);
  const [nameInInput, setNameInInput] = useState(currentName);

  if (inEditState) {
    return (
      <>
        <input
          name="fieldName"
          placeholder="Field name"
          style={{
            backgroundColor: isValidName(nameInInput, unavailableNames)
              ? 'white'
              : 'pink',
          }}
          value={nameInInput}
          onChange={event => setNameInInput(event.target.value)}
        ></input>
        <br />
        <button
          style={{
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          onClick={() => {
            isValidName(nameInInput, unavailableNames) &&
              onNameChange(nameInInput);
            setEditState(!inEditState);
          }}
        >
          {isValidName(nameInInput, unavailableNames) ? (
            '✅ Update'
          ) : (
            <>
              <span
                style={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red',
                }}
              >
                ↩
              </span>
              <span> Cancel</span>
            </>
          )}
        </button>
        <button
          style={{
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          onClick={() => {
            onDelete();
            setEditState(!inEditState);
          }}
        >
          ❌ Delete
        </button>
      </>
    );
  } else {
    return (
      <>
        <span
          style={{ fontWeight: 'bold', color: 'grey', cursor: 'pointer' }}
          onClick={() => setEditState(!inEditState)}
        >
          {'𝌡 '}
        </span>
        <span>{currentName}</span>
      </>
    );
  }
};
