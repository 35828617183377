import { EntityRelation, parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import LinearProgress from '@mui/material/LinearProgress';
import React, { FC, useCallback, useEffect, useState } from 'react';

interface ownershipProps {
  target: {
    name?: any;
  };
  targetRef: string;
}

interface teamNamesProps {
  name: string;
  link: string;
}

const DomainOwnedTeamsEntity: FC<{ domainOwner: string }> = ({
  domainOwner,
}) => {
  const [displayNameList, setDisplayNameList] = useState<ownershipProps[]>([]);
  const [teamNameList, setTeamNameList] = useState<teamNamesProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const catalogApi = useApi(catalogApiRef);

  const fetchGroupTeams = useCallback(async () => {
    const groupItem = {
      kind: 'group',
      name: domainOwner ?? 'dh_cop_dso',
      namespace: 'default',
    };
    const entityRef = parseEntityRef(groupItem);
    const value = await catalogApi.getEntityByRef(entityRef);
    const list =
      value?.relations?.filter(
        (group: EntityRelation) => group.type === 'parentOf',
      ) ?? [];

    if (list.length > 0) {
      // Set the results
      setDisplayNameList(list as any);
    } else {
      setDisplayNameList([]);
    }

    setIsLoading(false);
  }, [catalogApi, domainOwner]);

  useEffect(() => {
    fetchGroupTeams();
  }, [fetchGroupTeams]);

  const fetchNamesByGroupId = async () => {
    const promises = displayNameList.map(async element => {
      const response = (await catalogApi.getEntityByRef(
        element.targetRef,
      )) as any;
      return {
        link: element.targetRef,
        name: response?.spec?.profile?.displayName,
      };
    });

    const displayNameList_: teamNamesProps[] = await Promise.all(promises);

    setTeamNameList(displayNameList_);
  };

  useEffect(() => {
    fetchNamesByGroupId();
  }, [displayNameList.length]);

 

  return (
    <Card data-testid="entity-group-list">
      <CardHeader title="Teams" />
      <CardContent>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {displayNameList.length > 0 ? (
              <Table aria-label="group list" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>NAME</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamNameList.map(item => (
                    <TableRow key={item?.link}>
                      <TableCell>
                        <EntityRefLink entityRef={item?.link}>
                        <b 
                            >
                              {
                                item.name
                              }
                            </b>
                        </EntityRefLink>
                        
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              'No data'
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DomainOwnedTeamsEntity;
