/* eslint-disable */

// ESLint will ignore this entire file
import React, { useState } from 'react';
import { EditableTypeComponent } from '../EditableTypeComponent';

import { JSONType } from '../TreeTypeComponent/TreeTypeComponent';
import { isValidName } from '../../../utils';

interface AddFieldProps {
  unavailableNames: string[];
  onNewFieldType: (name: string, type: JSONType) => void;
}

export const AddFieldComponent = ({
  unavailableNames,
  onNewFieldType,
}: AddFieldProps) => {
  const [inEditState, setEditState] = useState(false);
  const [newType, setNewType] = useState(JSONType.string);
  const [newFieldName, setNewFieldName] = useState('');

  if (inEditState) {
    return (
      <>
        <hr />
        <table>
          <tbody>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>
                <input
                  name="fieldName"
                  placeholder="Field name"
                  value={newFieldName}
                  style={{
                    float: 'right',
                    width: '90%',
                    backgroundColor: isValidName(newFieldName, unavailableNames)
                      ? 'white'
                      : 'pink',
                  }}
                  onChange={event => setNewFieldName(event.target.value)}
                ></input>
              </td>
            </tr>
            <tr>
              <td>
                <label>Type</label>
              </td>
              <td>
                <div style={{ float: 'right', width: '90%' }}>
                  <EditableTypeComponent
                    type={newType}
                    onTypeChange={(type: any) => setNewType(type)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <button
                  disabled={!isValidName(newFieldName, unavailableNames)}
                  style={{
                    fontWeight: 'bold',
                    cursor: isValidName(newFieldName, unavailableNames)
                      ? 'pointer'
                      : 'default',
                  }}
                  onClick={() => {
                    onNewFieldType(newFieldName, newType);
                    setEditState(!inEditState);
                  }}
                >
                  ✅ Create
                </button>
                <button
                  style={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    float: 'right',
                  }}
                  onClick={() => setEditState(!inEditState)}
                >
                  ❌ Cancel
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
  return (
    <div
      style={{
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: 'grey',
        cursor: 'pointer',
        width: 'auto',
      }}
      onClick={() => setEditState(!inEditState)}
    >
      + Add new field
    </div>
  );
};
