import React, { FC } from 'react';
import AdrSearchResultListItem, { AdrSearchResultProps } from '../Adr';
import  LinearProgress  from '@mui/material/LinearProgress';

const AdrList: FC<{ result: AdrSearchResultProps[] }> = ({ result }) => {
  return (
    <>
      {result.length < 1 && <LinearProgress />}
      {result.filter(x=>!x.path.path.includes('index.md') && !x.path.path.includes('template.md') && x?.path?.title).map((item) => {
        return <AdrSearchResultListItem key={item.path.path.concat(item.path?.title)} data={item} />;
      })}
    </>
  );
};

export default AdrList;
