import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import {
  fetchData,
  urls,
} from '@internal/backstage-plugin-dh-dock-shared-common';
import { isArray } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import failureIcon from '../../app/src/assets/icons/failed.png';
import successIcon from '../../app/src/assets/icons/success.png';
import Modal from './components/Modal';

import 'react-toastify/dist/ReactToastify.css';

enum pipelineStatus {
  succeeded = 'succeeded',
  failed = 'failed',
}

interface pipleLineResponse {
  data: any;
  body: string;
}

function hideArticle() {
  const articles = document.getElementsByTagName('article'); // Get all <article> elements
  if (articles.length > 0) {
    articles[0].replaceWith(); // Remove the first <article> element found
  }
}
export const GlobalErrorInterceptor = () => {
  const fetchApi = useApi(fetchApiRef);
  const [showModal, setShowModal] = useState(false);
  const [responseData, setResponseData] = useState<pipleLineResponse>({
    body: '',
    data: {},
  });
  const [taskId, setTaskId] = useState('');
  const [trackSpace_Id, setTrackSpace_Id] = useState('');
  const [trackSpaceTicket, setTrackSpaceTicket] = useState('');

  const getPipeLineStatus = useCallback(async () => {
    try {
      const status = await fetchData(
        `${urls.getPiplineStatusByTasKId}${taskId}`,
      );
      if (status) {
        return status;
      }
    } catch (error) {
      console.error('Error fetching pipeline status:', error);
    }
  }, [taskId]);

  useEffect(() => {
    let intervalId: any = 0;
    if (window.location.pathname.split('/')[2] === 'tasks') {
      const id = window.location.pathname.split('/')[3];
      const isValidId = /^[a-zA-Z0-9]+$/.test(id);
      if (!isValidId) {
        throw new Error("Invalid ID format");
      }
      const getStatus = async () => {
        const status = await fetchData(`${urls.getPiplineStatusByTasKId}${id}`);
        if (status) {
          if (
            status.data &&
            (status.data.result === pipelineStatus.succeeded ||
              status.data.result === pipelineStatus.failed)
          ) {
            setShowModal(true);
            setResponseData(status);
            clearInterval(intervalId); // Clear the interval if you get the desired response
          }
        }
        if (taskId) {
          intervalId = setInterval(() => {
            getStatus(); // Call the async function inside the interval
          }, 5000); // Run every 5 seconds
        }

        // Cleanup on taskId change or component unmount
        return () => clearInterval(intervalId);
      };
      getStatus();
    }
  }, []);
  // # region Region code is for pipeline response
  useEffect(() => {
    let intervalId: any = 0;

    const checkStatus = async () => {
      const response = (await getPipeLineStatus()) as pipleLineResponse; // Call the async function
      if (response) {
        if (
          response.data &&
          (response.data.result === pipelineStatus.succeeded ||
            response.data.result === pipelineStatus.failed)
        ) {
          setShowModal(true);
          setResponseData(response);
          clearInterval(intervalId); // Clear the interval if you get the desired response
        }
      }
    };

    if (taskId) {
      intervalId = setInterval(() => {
        checkStatus(); // Call the async function inside the interval
      }, 5000); // Run every 5 seconds
    }

    // Cleanup on taskId change or component unmount
    return () => clearInterval(intervalId);
  }, [taskId, getPipeLineStatus]);
  //#endregion

  // # region Region code is for trackspace response
  useEffect(() => {
    let intervalId: any = 0;

    const checkTrackSpaceStatus = async () => {
      const trackSpaceId = await fetchData(
        `${urls.getCreatedTrackSpaceId}${trackSpace_Id}`,
      );
      if (trackSpaceId?.length && isArray(trackSpaceId)) {
        const id = trackSpaceId[0]?.body
          ?.split('ID:')[1]
          ?.split('"')[0]
          .replace(' ', '');
        setTrackSpaceTicket(id);
        clearInterval(intervalId); // Clear the interval if you get the desired response
      }
    };

    if (trackSpace_Id) {
      intervalId = setInterval(() => {
        checkTrackSpaceStatus(); // Call the async function inside the interval
      }, 5000); // Run every 5 seconds
    }

    // Cleanup on taskId change or component unmount
    return () => clearInterval(intervalId);
  }, [trackSpace_Id]);
  //#endregion
  useEffect(() => {
    if (window.location.pathname.includes('tasks')) {
      hideArticle();
    }
  }, [window.location]);

  useEffect(() => {
    const originalFetch = fetchApi.fetch.bind(fetchApi);

    // Override the fetch function with our custom error handler
    fetchApi.fetch = async (input, init) => {
      if (input.toString().includes('?term=')) {
        // Decode the URL first to replace encoded characters like %20 (space) with actual space
        input = decodeURIComponent(input.toString());
    
        // After decoding, replace the space character (%20) with a space, if needed
        input = input.replace(/%20/g, ' ');
    
        // Optionally, you can re-encode the URL after modification if necessary
        input = encodeURI(input);
      }
      if (
        input.toString().split('/api')[1] ===
        '/techdocs/static/docs/default/location/cop-devsecops-engineering/index.html' ||  input.toString().split('/api')[1] ===
        '/techdocs/static/docs/default/domain/cop_dso/index.html'
      ) {
        window.location.href = '/catalog/default/domain/cop_dso/guidelines';
      }
      // handle trackspace and pipeline response from server
      try {
        const response = await originalFetch(input, init);
        const _body = init?.body as any;
        if (
          _body &&
          JSON.parse(_body)?.templateRef?.split('/')[1] ===
            'create-trackspace-ticket'
        ) {
          if (response.ok) {
            const body = response.clone().json();
            const { id } = await body;
            setTrackSpace_Id(id);
          }
        }

        if (
          _body &&
          JSON.parse(_body)?.templateRef?.split('/')[1].includes('template')
        ) {
          if (response.ok) {
            const body = response.clone().json();
            const { id } = await body;
            setTaskId(id);
          }
        }

        return response;
      } catch (error) {
        console.error('API request failed:', error);
        throw error; // You can handle other types of errors here as well
      }
    };

    // Cleanup on unmount
    return () => {
      fetchApi.fetch = originalFetch;
    };
  }, [fetchApi]);

  const closeModal = () => {
    setShowModal(false);
    window.location.href = '/create';
  };

  if (trackSpaceTicket) {
    hideArticle();
    return (
      <>
        <Modal onClose={closeModal}>
          <img src={successIcon} alt="success" />
          <div>
            <h2>{`Trackspace ticket created successfully with ID ${trackSpaceTicket}. `}</h2>

            <p>
              Click &nbsp;
              <a
                href={`https://trackspace.lhsystems.com/browse/${trackSpaceTicket}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'blue' }}
              >
                here &nbsp;
              </a>
              to view the ticket
            </p>
          </div>
        </Modal>
        ;
      </>
    );
  }

  if (showModal) {
    hideArticle();
  }

  return showModal && responseData ? (
    <Modal onClose={closeModal}>
      {responseData?.data?.result === pipelineStatus.succeeded && (
        <img src={successIcon} alt="success" />
      )}
      {responseData?.data?.result === pipelineStatus.failed && (
        <img src={failureIcon} alt="failure" />
      )}
      <h2>
        {responseData?.data?.result === pipelineStatus.succeeded
          ? 'Your template execution was successful'
          : 'Your template execution failed'}
      </h2>
      {responseData?.data?.result === pipelineStatus.failed && (
        <p>
          Click &nbsp;
          <a
            href={responseData?.body?encodeURI(responseData.body):'#'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue' }}
          >
            here &nbsp;
          </a>
          to view pipeline
        </p>
      )}

      {responseData?.data?.result === pipelineStatus.succeeded && (
        <p>
          Click &nbsp;
          <a
            href={
              responseData?.data?.templateParameters?.adoProjectName
                ? encodeURI(`https://dev.azure.com/${responseData?.data?.templateParameters?.organization}/${responseData?.data?.templateParameters?.adoProjectName}/_git/${responseData?.data?.templateParameters?.adoRepositoryName}`)
                : encodeURI(`https://dev.azure.com/${responseData?.data?.templateParameters?.organization}/${responseData?.data?.templateParameters?.productName}`)
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue' }}
          >
            here &nbsp;
          </a>
          to view your repository
        </p>
      )}
    </Modal>
  ) : null; // This component doesn't render anything
};
