import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {  EntityLayout } from '@backstage/plugin-catalog';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useAsync } from 'react-use';

import CustomDomainAboutUs from '../../../pages/DomainAbout';
import DomainOwnedTeamsEntity from '../../../pages/DomainOwnedTeamsEntity';
import { CustomDomainEntityLayout } from '../../../pages/EntityLayout/CustomDomainEntityLayout';
import GuardRails from '../../../pages/GuardRails';
import JiraBoard from '../../../pages/JiraEpics/JiraBoard';
import CustomLinks from '../../../pages/Links';
import { RolesListCard } from '../../../pages/RolesResponsibilities';
import GuideLines from '../../../pages/GuideLines';
import { LinearProgress } from '@mui/material';
import { RelatedEntitiesCard } from '../../../pages/HasSystems';

interface DomainPageProps {
  entityWarningContent: JSX.Element;
}

export function DomainPage(props: DomainPageProps) {
  const { entity } = useEntity();
  const domainOwner = entity?.spec?.owner as string;
  const catalogApi = useApi(catalogApiRef);
  const groupInfoEntityRef = parseEntityRef(
    `groupinfo:${entity?.metadata?.namespace}/${entity?.metadata?.name}`,
  );
  const { value: groupInfoEntity } = useAsync(() =>
    catalogApi.getEntityByRef(groupInfoEntityRef),
  );
  const path = window?.location?.pathname;
  const isDevSecOps =
    path?.split('/')[3] === 'domain' && path?.split('/')[4] === 'cop_dso';
  const isCop = entity.metadata.tags?.toString() === 'cop';

  const [isLoading,setIsLoading]=useState(true)

  const updateLoading=(state:boolean)=>{
    
    setIsLoading(state)
  }
  return (
    <CustomDomainEntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {props.entityWarningContent}
          <Grid item md={6} xs={12}>
            <RolesListCard title="Contacts" />
            <br />
            <CustomDomainAboutUs />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomLinks groupInfoEntity={groupInfoEntity} />
            <br />
            <DomainOwnedTeamsEntity domainOwner={domainOwner} />
            <br />
            <RelatedEntitiesCard  title='Products' columns={[]} relationType={''} emptyMessage={''} emptyHelpLink={''} asRenderableEntities={function (): Entity[] {
              throw new Error('Function not implemented.');
            } }/>
            {/* <EntityHasSystemsCard variant="gridItem" title='Products' /> */}
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {(isDevSecOps || isCop) && (
        <>
          <EntityLayout.Route path="/guardRails" title="GuardRails">
          <>
          {isLoading && <LinearProgress/>}
          { <GuardRails setIsLoading={updateLoading} entity={entity} />
        }
          </>
          
          </EntityLayout.Route>
        </>
      )}
      {isDevSecOps && (
        <EntityLayout.Route path="/epics" title="Epics">
          <JiraBoard />
        </EntityLayout.Route>
      )}
      {isDevSecOps && (
        <EntityLayout.Route path="/guidelines" title="GuideLines">
          <GuideLines />
        </EntityLayout.Route>
      )}
    </CustomDomainEntityLayout>
  );
}
