import React, { FC } from 'react';

import './adr.css';

export interface AdrSearchResultProps {
  otherContent?:string
  content: {
    data?: string;
  };
  link: string;
  path: {
    date: string;
    path: string;
    status: string;
    title: string;
    type: string;
  };
}

const AdrSearchResultListItem: FC<{ data: AdrSearchResultProps }> = ({
  data,
}) => {
  const linkUrl = data.link.split('/');
  const pathLink = linkUrl[0].split(':');
  const fullLink = '/catalog/'.concat(
    pathLink[1]
      .concat('/')
      .concat(pathLink[0])
      .concat('/')
      .concat(linkUrl[1])
      .concat('/adr/')
      .concat(data.path.path),
  );

  

  const onLinkClick = (url: string) => {
    return window.open(url, '_blank');
  };

  return (
    <>
      <div>
        <div
          className="adrLink"
          onClick={() => onLinkClick(fullLink)}
          role="button"
          tabIndex={-1}
          onKeyDown={()=>onLinkClick('')}
        >
          {data?.path?.title?.includes(':')
            ? data?.path?.title.split(':')[1]
            : data?.path?.title}
        </div>
        <div
          className="adrContent"
          dangerouslySetInnerHTML={{
            __html: data?.content?.data?.substring(0, 1000) || data?.otherContent!!.substring(0, 1000),
          }}
        />
      </div>
      <div className="adrSubContentContainer">
        <div className="adrSubContentContainer__content">
          Entity:{' '}
          {linkUrl[0]
            .replace('default', '')
            .concat(linkUrl[1].replace('/', ''))}
        </div>
        {data.path.date && (
          <div className="adrSubContentContainer__content">
            Date: {data.path.date}
          </div>
        )}
        {data.path.status && (
          <div className="adrSubContentContainer__content">
            Status: {data.path.status}
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

export default AdrSearchResultListItem;
